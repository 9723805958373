import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/36d6a50c-02b5-4716-b03c-d013d4d66edc/photo_2024-04-25_10-23-52.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/7afa7989-2c20-4772-b3d3-9c52d6368226/IMAGE_2024-04-26_214234.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/7f018ae9-966f-4aa5-a2f8-956e76236733/IMAGE+2024-04-27+16%3A19%3A43.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "/img2.jpg",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "/img3.jpg",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "/img4.jpg",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "/img5.jpg",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "/img6.jpg",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/f9d80e5e-e4ce-4c53-8ada-ba1c37fdd90b/good+boy+cyberpunk.png?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/460e1b2c-1e9f-4773-9f36-801f1a017750/IMAGE+2024-04-27+11%3A02%3A48.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/70e8e191-e7f4-4e02-8275-943738685968/IMAGE+2024-04-27+16%3A19%3A04.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/5f896310-674c-46eb-bd6f-aaaa4440fcff/IMAGE+2024-04-27+16%3A19%3A05.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/923a515a-fb28-4a47-aeae-99e22d715516/IMAGE+2024-04-27+16%3A19%3A11.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/14bfcc17-5020-4f71-b9a7-1fbef5040311/IMAGE+2024-04-27+16%3A19%3A16.jpg?format=300w",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: "https://images.squarespace-cdn.com/content/v1/6629c09ab2d91f1856edec79/a5253ae3-8f40-4beb-9844-93d1b66fd5d6/photo_2024-04-25_10-23-30.jpg?format=300w",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h1 style={{color:"white", fontStyle:"bold", textAlign:"center", margin:"0 0 50px 0"}}>Meme Gallery</h1>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
