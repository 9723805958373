import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <h1 id="meme_gen" style={{color:"white", fontStyle:"bold", textAlign:"center", margin:"0 0 50px 0"}}>Meme Generator</h1>
      <div class="iframe-container" style={{position:"relative", overflow:"hidden", width:"100%", height:"100vh", paddingTop:"56.25%", scrollbarWidth:"none"}}>
    <iframe src="https://goodboymemegenerator.vercel.app/" style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", border:"0", scrollbarWidth:"none"}} frameborder="0" allowfullscreen></iframe>
</div>
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
