import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon2 from '../assets/img/twitter-x.svg';
import navIcon1 from '../assets/img/telegram.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/discord.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
          <h1 style={{color:"white", fontStyle:"bold"}}>Good Boy on Solana</h1>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
            <a href="https://x.com/goodboydoggoSOL"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/goodboyxsolana/"><img src={navIcon3} alt="" /></a>
                <a href="https://t.me/boydoggo"><img src={navIcon1} alt="" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
