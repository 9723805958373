import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import navIcon2 from '../assets/img/twitter-x.svg';
import navIcon1 from '../assets/img/telegram.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/discord.svg';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <h1 style={{color:"white", fontStyle:"bold"}}>Good Boy on Solana</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="https://www.dextools.io/app/en/solana/pair-explorer/7gV6LvfWeMgwNq6FzYR237Lhi5mCkGz8P4eywzJPzFqS?t=1717018575405" target={"_blank"} className={'navbar-link'}>Dextools</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://x.com/Goodboy_solana" target={"_blank"}><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/goodboydoggosol/" target={"_blank"}><img src={navIcon3} alt="" /></a>
                <a href="https://t.me/boydoggo" target={"_blank"}><img src={navIcon1} alt="" /></a>
              </div>
              <a href='https://raydium.io/swap?outputCurrency=GmEXg8FwpULzVb15r41CNB5qFnRcXCrEyZSmD3X7sv2e' target={"_blank"}>
                <button className="vvd"><span>Buy $BOY</span></button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
